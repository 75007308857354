import { DateTime } from 'luxon';

const locale = 'pt-br';
const defaultFormat = 'dd/MM/yyyy';

const DateAth = () => {
  return {
    transformToDateTime(date) {
      if (date) {
        return DateTime.fromISO(date);
      }
      return null;
    },

    dateNow() {
      return DateTime.now();
    },

    timeNow() {
      return this.getTime(DateTime.now());
    },

    getDate(date) {
      if (date === null || date === undefined) return '---';
      const zone = typeof date === 'string' && date.includes('T00:00:00') ? 'utc' : 'America/Sao_Paulo'
      return !date ? '---' : DateTime.fromISO(date, { 'zone': zone }).toFormat(defaultFormat);
    },

    getTime(date) {
      if (date === null) return '---';
      const zone = typeof date === 'string' && date.includes('T00:00:00') ? 'utc' : 'America/Sao_Paulo'
      return DateTime.fromISO(date, { 'zone': zone }).toFormat('HH:mm:ss');
    },

    getDateTime(date) {
      if (date === null) return '---';
      const zone = typeof date === 'string' && date.includes('T00:00:00') ? 'utc' : 'America/Sao_Paulo'
      return DateTime.fromISO(date, { 'zone': zone }).toFormat(`${ defaultFormat } HH:mm`);
    },

    getDateTimeSeconds(date) {
      if (date === null) return '---';
      const zone = typeof date === 'string' && date.includes('T00:00:00') ? 'utc' : 'America/Sao_Paulo'
      return DateTime.fromISO(date, { 'zone': zone }).toFormat(`${ defaultFormat } HH:mm:ss`);
    },

    getDateFormat(date, format) {
      if (date === null) return '---';
      const zone = typeof date === 'string' && date.includes('T00:00:00') ? 'utc' : 'America/Sao_Paulo'
      return DateTime.fromISO(date, { 'zone': zone }).setLocale(locale).toFormat(format);
    },

    getNameMonthYear(date) {
      if (date === null || date === '') return '---';
      return this.getDateFormat(date, 'MMM/yyyy').replace('.', '');
    },

    getDateOfInput(date) {
      if (date === null) return '---';
      const valueData = new Date(date.valueOf());
      return valueData.toISOString().replace(/\.\d{3}Z$/, '');
    },

    addDays(date, value, format = null) {
      if (date === null) return '---';
      if (format) {
        return DateTime.fromISO(date).plus({ days: value }).setLocale(locale).toFormat(format);
      }
      return DateTime.fromISO(date).plus({ days: value }).setLocale(locale);
    },

    minusDays(date, value, format = null) {
      if (date === null) return '---';
      if (format) {
        return DateTime.fromISO(date).minus({ days: value }).setLocale(locale).toFormat(format);
      }
      return DateTime.fromISO(date).minus({ days: value }).setLocale(locale);
    },

    addMonths(date, value, format = null) {
      if (date === null) return '---';
      if (format) {
        return DateTime.fromISO(date).plus({ months: value }).setLocale(locale).toFormat(format);
      }
      return DateTime.fromISO(date).plus({ months: value }).setLocale(locale);
    },

    addYears(date, value, format = null) {
      if (date === null) return '---';
      if (format) {
        return DateTime.fromISO(date).plus({ years: value }).setLocale(locale).toFormat(format);
      }
      return DateTime.fromISO(date).plus({ years: value }).setLocale(locale);
    },

    subtractMonths(date, value, format = null) {
      if (date === null) return '---';
      if (format) {
        return DateTime.fromISO(date).minus({ months: value }).setLocale(locale).toFormat(format);
      }
      return DateTime.fromISO(date).minus({ months: value }).setLocale(locale);
    },

    getStartOfMonth(addMonth = null) {
      if (addMonth) return DateTime.now().plus({ months: addMonth }).startOf('month');
      return DateTime.now().startOf('month');
    },

    getEndOfMonth(addMonth) {
      if (addMonth) return DateTime.now().plus({ months: addMonth }).endOf('month');
      return DateTime.now().endOf('month');
    },

    getYearNow() {
      return DateTime.now().toFormat('yyyy');
    },

    diffBetweenTwoDatesInDays(date1, date2) {
      if (date1 && date2) {
        date1 = this.transformToDateTime(date1);
        date2 = this.transformToDateTime(date2);

        let diff = date1.diff(date2, ['days']);
        diff = diff.toObject();

        return diff.days < 0 ? Math.trunc(diff.days) : Math.round(diff.days);
      }

      return undefined;
    },

    dateNowEndOfDay() {
      const date = DateTime.now().endOf('day')
      return date.setLocale(locale).toFormat('yyyy-MM-dd');
    },

    timeIsToEqualOrBiggerThanNow(date) {
      if (date === null) return false;
      const time = DateTime.fromISO(date).toFormat('HH:mm:ss')
      return this.timeNow() <= time;
    },

    getMonthNow() {
      return DateTime.now().toFormat('MM');
    },

    getDateCnab(date) {
      if (date === null || date === undefined || Number(date) == 0)
        return "-";
      date = String(date).padStart(8, "0")
      return date.substring(0, 2) + "/" + date.substring(2, 4) + "/" + date.substring(4, 8);
    },

    getDateUtc(date) {
      if (!date) return '---';
      return DateTime.fromISO(date).toFormat(`${ defaultFormat }`);
    },

    addDMonthsNow(dateData, month, months) {
      const date = new Date(dateData.getFullYear(), month, dateData.getDate())
      const d = date.getDate();
      date.setMonth(date.getMonth() + +months);

      if (date.getDate() !== d) date.setDate(0);

      return date.toISOString();
    },

    getDateCreditCard(date, type) {
      if (type === 'month')
        return parseInt(date.substring(0, 2))
      if (type === 'year')
        return parseInt(`${ this.getYearNow().substring(0, 2) }${ date.substring(3, 5) }`)
    },

    getNameOfMonth(month = 0, isComplete = false) {
      let months = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez']

      if (isComplete)
        months = [
          'January',
          'February',
          'March',
          'April',
          'May',
          'June',
          'July',
          'August',
          'September',
          'October',
          'November',
          'December'
        ]

      return months[month]
    },

    getDateEndOfMonth(date, format = 'yyyy-MM-dd') {
      if (date === null || date === undefined) return '---';
      const zone = typeof date === 'string' && date.includes('T00:00:00') ? 'utc' : 'America/Sao_Paulo'
      return DateTime.fromISO(date, { 'zone': zone }).endOf('month').toFormat(format)
    },

    getFirstDayOfMonth(date, format = 'yyyy-MM-dd') {
      if (date === null || date === undefined) return '---';
      const zone = typeof date === 'string' && date.includes('T00:00:00') ? 'utc' : 'America/Sao_Paulo';
      return DateTime.fromISO(date, { zone }).startOf('month').toFormat(format);
    },

    isDateInFuture(date) {
      if (date === null || date === undefined) return false
      const inputDate = new Date(date.split('/').reverse().join('/'))
      const today = new Date()
      return inputDate > today;
    },

    parseDate(date) {
      if (date === null || date === undefined) return '';

      const dateTimeParse = new Date(date).toLocaleString('pt-BR')
      const dateParse = dateTimeParse.split(' ')[0]

      return dateParse.substring(6, 10) + "-" + dateParse.substring(3, 5) + "-" + dateParse.substring(0, 2);
    },

    substractDays(date, value, format = null) {
      if (date === null) return '---';
      const resultDate = DateTime.fromISO(date).minus({ days: value }).startOf('day');
      if (format) {
        return resultDate.setLocale(locale).toFormat(format);
      }
      return resultDate.setLocale(locale).toFormat(defaultFormat);
    },

    getCompleteNameOfMonth(dateString) {
      const date = new Date(dateString)
      const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
      ]
      if (!isNaN(date.getTime())) {
        return months[date.getUTCMonth()]
      } else {
        return null
      }
    },
    setDay(date, day) {
      const zone = typeof date === 'string' && date.includes('T00:00:00') ? 'utc' : 'America/Sao_Paulo'
      return DateTime.fromISO(date, { 'zone': zone }).set({ day })
    },
    isDecemberJanuaryOrFebruary() {
      const dateNow = DateTime.now()
      const monthNow = dateNow.month

      return monthNow === 12 || monthNow === 1 || monthNow === 2
    }
  };
};

export default DateAth();
